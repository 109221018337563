/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './variables.scss';

@font-face {
  font-family: "UniverseLTStd";
  src: url('./assets/fonts/UniversLTStd-Light.otf');
}

@font-face {
  font-family: "UniverseLTStd";
  font-weight: bold;
  src: url('assets/fonts/UniversLTStd-Bold.otf');
}

body, a {
  font-family: "UniverseLTStd", Helvetica;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

p {
  height: auto;
}

deinsport-error-message {
  position: fixed;
  width: 80%;
  left: 10%;
  z-index: 10000;
}

.btn-primary {
  background-color: $colorPrimary;
  border-color: $colorPrimary;
}

.btn-primary:hover {
  background-color: $colorSecondary;
  border-color: $colorSecondary;
}

.form-control:focus {
  border-color: $colorPrimary;
  box-shadow: 0px 0px 2px 2px $colorPrimary;
  -webkit-box-shadow: 0px 0px 2px 2px $colorPrimary;
  -moz-box-shadow: 0px 0px 2px 2px $colorPrimary;
}

.tooltip {
  position: absolute !important;
}

.icon {
  width: 12px;
  height: 12px;
}

.hyperlink-primary {
  color: $colorPrimary;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $colorSecondary;
  }

  &-bold {
    font-weight: bold;
  }
}

.spinner-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.error {
  &-input {
    box-shadow: 0px 0px 8px 4px rgba(255,0,0,0.5);
    -webkit-box-shadow: 0px 0px 8px 4px rgba(255,0,0,0.5);
    -moz-box-shadow: 0px 0px 8px 4px rgba(255,0,0,0.5);
  }
}

// Overwrite Google Maps sizing.
.map-container {
  width: 100% !important;
  height: 700px !important;
}
